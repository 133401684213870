import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import { Provider as AlertProvider, positions } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import GlobalStatesStore from './globalStates/Store';
import App from './App';
import './index.scss';

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
};

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <GlobalStatesStore>
      <App />
    </GlobalStatesStore>
  </AlertProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
