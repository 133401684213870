import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const userDetails = JSON.parse(localStorage.getItem("oa")) || {};

const initialState = {
  userDetails,
};

export const GlobalContext = createContext(initialState);

const StateStore = ({ children }) => {
  const [states, dispatch] = useReducer(Reducer, initialState);
  return (
    <GlobalContext.Provider
      value={{
        states,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default StateStore;
