import React, { lazy, useContext } from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { GlobalContext } from "../globalStates/Store";

const AppContainer = (props) => {
  const {
    states: {
      userDetails: { role },
    },
  } = useContext(GlobalContext);

  const routeLogin = import("../pages/Login");
  const routeApp = import("../pages/App");
  const Login = lazy(() => routeLogin);
  const App = lazy(() => routeApp);

  const getDefaultRoute = () => {
    if (role) return "dashboard";
    return "login";
  };

  return (
    <BrowserRouter>
      <React.Suspense fallback={""}>
        <Switch>
          <Route exact path="/login" render={() => <Login {...props} />} />
          <Redirect exact from="/" to={getDefaultRoute()} />
          <Route render={() => <App {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};
export default AppContainer;
